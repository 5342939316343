import _ from 'lodash'
import store from '@/store'
import { messageType } from '@/constant'
import { EaseChatClient } from '@/IM/initwebsdk'

import defaultGroupAvatarUrl from '@/assets/images/avatar/jiaqun2x.png'
import defaultSingleAvatarUrl from '@/assets/images/avatar/theme2x.png'
const { SESSION_MESSAGE_TYPE, CHAT_TYPE, ALL_MESSAGE_TYPE, CUSTOM_TYPE } =
    messageType

//处理最后一条消息预览
const handleLastMsgContent = (msgBody) => {
    const { type, msg } = msgBody
    let resultContent = ''
    //如果消息类型，在预设非展示文本类型中，就返回预设值
    if (SESSION_MESSAGE_TYPE[type]) {
        resultContent = SESSION_MESSAGE_TYPE[type]
    } else if (type === ALL_MESSAGE_TYPE.CUSTOM) {
        //如果为自定义类型消息就匹配自定义消息对应的lastmsg文本
        if (msgBody.customEvent) {
            (CUSTOM_TYPE[msgBody.customEvent] &&
                (resultContent = CUSTOM_TYPE[msgBody.customEvent])) ||
                ''
        }
    } else if (msgBody.isRecall) {
        //如果是撤回消息，则展示撤回消息类型文本
        resultContent = '撤回了一条消息'
    } else {
        resultContent = msg
    }
    return resultContent
}

export default function (conversationItem) {
    const updatedConversation = {}
    findIncludesConversation(conversationItem)
    //根据传入的消息进入会话列表进行查询
    function findIncludesConversation(conversation, unreadNum) {
        const localConversationList =
            store.state.Conversation.conversationListData
        
        const { from, to } = conversation.lastMessage
        const loginUserId = EaseChatClient.user
        const isSender = loginUserId === from
        const listKey = isSender ? to : from
        //不存在则创建
        if (!localConversationList[listKey]) {
            const newCoversation = buildConversationItem('create', conversation, unreadNum)
            updatedConversation[listKey] = newCoversation
        }
        //存在则更新
        else if (localConversationList && localConversationList[listKey]) {
            const theData = _.cloneDeep(
                store.state.Conversation.conversationListData[listKey]
            )
            const updatedCoversation = buildConversationItem(
                'update',
                conversation,
                theData
            )

            updatedConversation[listKey] = updatedCoversation
        }
    }
    //构建会话方法
    function buildConversationItem(operateType, conversation, theData) {
        //type create构建 update更新
        /**
         * 
         * conversationType: "" 会话类型
         * conversationInfo: { 会话信息详情
             *  name: ,
             * avatarUrl:
         }, 
            fromInfo: {},消息来源id
            unreadMessageNum: 0, 未读数
            latestMessage: { 
            msg:"",
            type: "",
            ext: { },
            }, //最近一条消息消息体
            latestMessageId: "", 最近消息的消息mid
            latestSendTime:"", 最近一条消息的发送时间,
        */
        const msgBody = conversation.lastMessage
        const loginUserId = EaseChatClient.user
        const { from, ext, id, time, to, type } = msgBody
        const isSender = loginUserId === from
        const listKey = isSender ? to : from

        //操作类型为新建
        if (operateType === 'create') {
            const state = {
                conversationType: "singleChat",
                conversationKey: listKey,
                conversationInfo: {
                    name: '',
                    avatarUrl: defaultSingleAvatarUrl
                },
                fromInfo: {
                    fromId: from,
                    fromName: ''
                },
                targetId: listKey,
                unreadMessageNum: conversation.unread_num,
                latestMessage: {
                    msg: handleLastMsgContent(msgBody),
                    // SESSION_MESSAGE_TYPE[type] ||
                    // (msgBody.isRecall && '撤回了一条消息') ||
                    // msg,
                    type: type,
                    ext: { ...ext }
                },
                latestMessageId: id,
                latestSendTime: time || Date.now()
            }

            state.conversationInfo.name = isSender ? to : from

            return state
        }
        //操作类型为更新
        if (operateType === 'update') {
            const updatedState = {
                fromInfo: {
                    fromId: from,
                    fromName: ''
                },
                latestMessage: {
                    msg: handleLastMsgContent(msgBody),
                    // SESSION_MESSAGE_TYPE[type] ||
                    // (msgBody.isRecall && '撤回了一条消息') ||
                    // msg,
                    type: type,
                    ext: { ...ext }
                },
                targetId: listKey,
                latestMessageId: id,
                latestSendTime: time || Date.now(),
                unreadMessageNum: conversation.unread_num
            }
            return _.assign(theData, updatedState)
        }
    }
    
    return updatedConversation 
}